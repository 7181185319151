
@font-face {
    font-family: 'Helvetica_Reg';
    src: url('../../public/fonts/Helvetica_Regular.woff2');
    font-weight: normal;
}

@font-face {
    font-family: 'Helvetica_Reg';
    src: url('../../public/fonts/Helvetica_Bold.woff2');
    font-weight: bold;
}


@font-face {
    font-family: 'HelveticaNeueLTStd';
    font-weight: 400;
    font-display: swap;
    src: url('../../public/fonts/39840C_0_0.woff2') format('woff2');
  }
  @font-face {
    font-family: 'HelveticaNeueLTStd';
    font-weight: 500;
    font-display: swap;
    src: url('../../public/fonts/39840C_1_0.woff2') format('woff2');
  }
  @font-face {
    font-family: 'BemboStd';
    font-weight: 400;
    font-display: swap;
    src: url('../../public/fonts/39840C_2_0.woff2') format('woff2');
  }