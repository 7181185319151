footer {
    /* padding: 20px 5% 20px 5%; */
    background: white;
    position: static;
    bottom: 0;
    left: 0;
    display: flex;
    right: 0;
    z-index: 0;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
}

.footer_newsletter > form {
    width: 100%;
    display: flex;
    max-width: 570px;
}
.footer_newsletter > form > input{
    width: 70%;
    border: var(--border-weight) solid var(--black);
}
.footer_copyright {
    font-size: 10px;
    color: var(--black);
    letter-spacing: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.footer_newsletter > form > button[type='submit'] {
    width: 30%;
    height: 40px;
    box-sizing: border-box;
    border: var(--border-weight) solid var(--black);
    border-left: 0;
    background-color: var(--black);
    color: var(--beige);
}
.footer_copyright {
    font-size: 10px;
    color: var(--black);
    letter-spacing: 0px;
    width: 100%;
    padding: 60px 5%;
    box-sizing: border-box;
}
.footer__inner {
    margin-bottom: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.footer_item.--newsletter {
    flex: 0 0 100%;
    justify-content: flex-start;
    display: flex;
}
form.newsletter__form_footer {
    border-bottom: 1px solid #ddd;
}
form.newsletter__form_footer {
    height: 40px;
    position: relative;
    width: 40%;
    max-width: 355px;
}
form.newsletter__form_footer > input {
    border: 0;
    height: 40px;
    font-size: 10px;
    width: 100%;
}
form.newsletter__form_footer > button {
    border: 0;
    height: 40px;
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--black-02);
}
.footer_newsletter {
    display: flex;
    padding: 20px 5%;
    width: 100%;
    box-sizing: border-box;
    border-bottom: var(--border-weight) solid var(--black);
    background: var(--beige);
    justify-content: center;
    border-top: var(--border-weight) solid var(--black);
}


.footer_mail {
    display: flex;
    padding: 20px 40px;
    box-sizing: border-box;
    background: var(--black);
    justify-content: center;
    border-radius: 2rem;
    border: var(--border-weight) solid var(--black);
    color: white;
    font-weight: 500;
    transition: all 0.5s;
}
.footer_mail:hover {
    display: flex;
    padding: 20px 40px;
    box-sizing: border-box;
    background: white;
    justify-content: center;
    border-radius: 2rem;
    color: var(--black);
    font-weight: 500;
}
.footer_mail_wrapper{
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}