@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./fonts.css');
:root{
  --black: #0f0f0f;
  --font-family: 'HelveticaNeueLTStd';
  --beige: #F8F7F5;
  --grey: #333333;
  --border-weight: 0.5px;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  color: rgb(var(--foreground-rgb));
  font-family: var(--font-family);
  font-size: 12px;
  max-width: 100vw;
  letter-spacing: 0.04em;
  overscroll-behavior: none;
}
.radius-1{
  border-radius: 1em;
}
a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/*  padding: 1.25rem 20px 0;
*/
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 1rem;
  font-family: HelveticaNeueLTStd;
  z-index: 2;
  height: 40px;
  display: flex;
  align-items: center;
}
img, video{
  width: 100%;
}
.product__spec_closed{
  border-top: var(--border-weight) solid var(--black);
}
.product__spec_title.--opened{
  width: calc(100% - 40px);
  border: 0;
  font-weight: 200;
  display: flex;
  padding: 20px;
  margin: 0;
  height: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.product__spec_title {
  margin: 0;
  font-size: 10px;
  font-family: 'HelveticaNeueLTStd';
  font-weight: 400;

}
.product__spec_title.--opened * {
  margin: 0;
  padding: 0;
}
.header_logo {
  font-weight: 600;
}
.column-header {
  min-width: 16rem;
  text-align: left;
}
#menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 1.25rem;
  position: absolute;
  left: 20px;
  top: 0;
}
#menu{
  background-color: hsla(0, 0%, 90%, .69);
  background-color: var(--color-bg-input, hsla(0, 0%, 90%, .69));
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 2.5em;
  justify-content: center;
  padding: 3px;
  position: relative;
}

.gsp-slide {
  display: flex !important;
  align-items: center;
}
.gsp-slide > * {
  height: 500px;
  padding-right: 40px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.gsp-slider {
  height: 100vh;
}

.swiper-slide.swiper-slide-active.gsp-slide {
  opacity: 1;
}

.swiper-slide.gsp-slide {
  opacity: 1;
  transition: all .5s;
}
.full_fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: white;
}

.--index-loader {
  background-color: var(--beige);
}
.full_fixed.--index-loader-black {
  background-color: var(--grey);
  z-index: 95;
  height: 100vh;
  
}
.video-container {
  flex-grow: 1; /* Make sure the video grows with the parent container */
  width: 100%;
  height: 100%;
}
.title-section{
  padding: 0 1rem;
}
.extra_slide {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  border: 0.5px solid var(--black);
}
.video-container > video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video fills the container without distorting */
}

.h1_loader{
  font-weight: 300;
}
.slide-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

#hero-index > .text-content > *{
  transform: translateY(100%);
  font-family: HelveticaNeueLTStd;
  text-transform: uppercase;
}
#hero-index > .text-content > h2{
  font-weight: 500;
}

.header__wrapper {
  position: relative;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
  padding: 0 5%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/*.header_logo {
  font-weight: 600;
  display: flex;
  font-size: 20vh;
  align-items: center;
  justify-content: center;
  padding-top: 30vh;
}
  */
  .footer_wrapper{
    padding: 10vh 0;
    font-family: 'HelveticaNeueLTStd';
  }
.header_wrapper {
    display: flex;
    justify-content: flex-start;
}

.grid_project {
  display: flex;
  gap: 0px;
  flex-wrap: wrap;
  flex-direction: column;
}


.product__spec_button {
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border: 0;
  font-weight: 600;
  display: flex;
  padding: 20px 1rem;
  margin: 0;
  height: 100%;
  letter-spacing: 0;
  font-size: 10px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.Collapsible__Plus{
  position: relative;
}
.Collapsible__Plus::after, .Collapsible__Plus::before{
  color: var(--black);
  height: 8px;
  width: var(--border-weight);
}

.Collapsible__Plus::before{
  color: var(--black);
  height: var(--border-weight);
  width: 8px;
}
.product__spec_button[aria-expanded=true] .Collapsible__Plus::before {
  opacity: 0;
}
.Collapsible__Plus::after, .Collapsible__Plus::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) rotate(-90deg);
  background-color: currentColor;
  transition: transform .4s ease-in-out,opacity .4s ease-in-out;
}
.product__spec_button[aria-expanded=true] .Collapsible__Plus::after, .product__spec_button[aria-expanded=true] .Collapsible__Plus::before {
  transform: translate(-50%,-50%) rotate(90deg);
}
.product__spec_content[aria-expanded="true"] {
  visibility: visible;
  border-top: var(--border-weight) solid var(--black);
  transition: height .35s ease-in-out,visibility 0s ease-in-out;

}
canvas {
  display: block;
  width: 100vw;
  height: 100vh;
}
.product__spec_content[aria-expanded="false"] {
  height: 0px;
  border-top: var(--border-weight) solid white;
  transition: height .35s ease-in-out,visibility 0s ease-in-out 20s, border-color 0.35s ease-in-out 0s;

}
.product__spec_title.--opened * {
  margin: 0;
  padding: 0;
}
.product__spec_content{
  display: block;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}
.product__spec_closed{
  border-top: var(--border-weight) solid var(--black);
}
.product_spec_content--container[aria-expanded="false"] {
  opacity: 0;
  transition: opacity .35s ease-in-out;


}
.product_spec_content--container[aria-expanded="true"] {
  opacity: 1;
  transition: opacity .35s ease-in-out;
  animation-delay: 0s;

}

.product_spec_content--container > p{
  margin: 0px;
}
.product_spec_content--container > * {
  width: calc(100% - 0px);
}
.product_spec_content--container > * * {
  width: 100%;
  height: 100%;
}
#hero-index {
  height: 80vh;
}
.grid_project > h2 {
  padding: 10px 20px;
  font-weight: 500;
}
.footer_newsletter > form > input {
  width: 70%;
  border: var(--border-weight) solid var(--black);
  font-size: 12px;
  padding-left: 10px;
}
.content-work {
  padding: 20px;
  display: flex;
  justify-content: center;
}
.left, .right {
  width: 50%;
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
}
.me_content {
  display: flex;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.me_content img {
  width: auto;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 651px) {
  .me_content{
    flex-direction: column;
    height: auto ;
  }
  .left, .right{
    width: 100%;
    padding: 20px 1rem;
  }
  #hero-index {
    height: 60vh;
    text-align: center;
    padding:  0 10px ;
}
#meSection{
  height: 60vh !important;
}
#mePic, #meText > p{
  width: 65% !important;
}
}


@media (min-width: 651px) {
  .left {
    padding: 50px;
  }
}
@media (min-width: 1000px) {
  .left {
    padding: 100px;
  }
  
}



.content-work.project-spec:not(:last-child){
  border-bottom: var(--border-weight) solid var(--black);
}
.gsp_swiper{
  padding: 1rem !important;
}

.slide-wrapper > img, 
.slide-wrapper > video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.content-work.project-spec {
  padding: 20px;
  grid-template-columns: 1fr 1fr;
  display: grid;
  justify-content: flex-start;
}
.slide-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.mt-70vh{
  margin-top: 70vh;
}
div#main {
  z-index: 1;
  position: relative;
  background-color: white;
}
#mePic{
  height: 100%;
  width: 33%;
}
#meSection {
  height: 80vh;
  position: sticky;
  top: 0;
}
.title-scroll.overflow-hidden {
  padding: 1rem 0;
  border: var(--border-weight) solid var(--black);
  border-left: 0;
  border-right: 0;
  max-width: 100vw;
  font-style: italic;
  font-weight: 500;
}

#titleServicesSection{
  margin-left: -100px;
}

.services-section_content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
  align-content: flex-start;
}

.services-section_service {
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
}

.services-section_service:first-child{
  border-right: 0;
}
#message {
  position: fixed;
  right: 2em;
  bottom: 2em;
  border-radius: 1em;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 1em 2em;
  background-color: hsl(0deg 0% 20% / 20%);
  z-index: 995;
  font-size: 0.9em;
  display: flex;
  gap: 1em;
}
.js-up{
  transform: translateY(100%);
}
.f-2em{
  font-size: 2em;
}
h1.h1_preloader {
  font-size: 9em;
  font-weight: 1000;
  font-family: 'HelveticaNeueLTStd';
  letter-spacing: 0.15em;
  color: white;
  position: absolute;
  top: 0vh;
  bottom: 0vh;
  left: 0vw;
  right: 0vw;
  display: flex;
  z-index: 96;
  justify-content: center;
  align-items: center;
}
.--no-scroll{
  overflow: hidden;
}

.data-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  pointer-events: none;
  flex-direction: column-reverse;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.title {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.title.visible {
  opacity: 1;
}

@media (max-width: 1200px) {
  #mePic, #meText > p{
    width: 50%;
  }
  #mePic > img{
    height: 110%;
    object-fit: cover;
  }
}

@media (max-width: 1500px) {
  #mePic, #meText > p{
    width: 33%;
  }
  #mePic > img{
    height: 110%;
    object-fit: cover;
  }
}

#meText{
  padding: 2rem 1rem;
}